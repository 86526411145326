export const esp = {
  general: {
    requestDemo: "Solicita una demo",
    legal: "Aviso de Privacidad"
  },
  menu: {
    solutions: "Soluciones",
    pmSection: "Gestión de compras",
    pmSummary: "Netcurio ofrece una comunicación eficiente cliente-proveedor con trazabilidad en las ordenes de compras y en todo el proceso de compras.",
    imSection: "Gestión de facturas",
    imSummary: "Validación automática de facturas con todas la reglas de negocio que tu compañia require para el pago de una factura. Incluidas las regulaciones fiscales.",
    pcSection: "Control de pago",
    pcSummary: "Puedes programar y mostrarle a tus proveedores el estatus del pago de sus facturas.",
    dbrSection: "Control: Indicadores y Reportes",
    dbrSummary: "Ten un control 360° con visibilidad y trazabilidad durante todo el proceso de Compras y Cuentas por Pagar.",
    company: "Compañía",
    auSection: "Sobre Nosotros",
    auSummary: "Nuestro foco es asegurar una gestion eficiente del proceso de compras, con reducción de costos operativos, visibilidad, trazabilidad...",
    diffSection: "Diferenciadores",
    diffSummary: "La adopción a nuestra plataforma permite a chicas, medianas y grandes empresas a optimizar su proceso de compras.",
    contact: "Contacto",
    login: "Iniciar Sesión"
  },
  menuMobile: {
    homeMobile: "Inicio",
    langMobile: "Idioma",
    engMobile: "Inglés",
    espMobile: "Español",
    legal: "Aviso de Privacidad"
  },
  index: {
    indexTitle: "NETCURIO - La Evolución de la Contratación | Procurar pagar plataforma en la nube",
    bannerHome: "La Evolución de la Contratación",
    demoHome: "¿Quieres solicitar una demo?",
    contactHome: "Contáctanos",
    benefitsHome: "Beneficios",
    commTitle: "Comunicación",
    commText: "Comunicación eficiente cliente - proveedor a través de la plataforma Netcurio.",
    effTitle: "Eficiencia",
    effText: "Reducción de tiempo y esfuerzo por la automatización del proceso de compras y revisión de facturas.",
    compTitle: "Cumplimiento",
    compText: "Trazabilidad de todo el proceso de compras desde precios, cantidades, registro de mercancía y manejo de excepciones.",
    costTitle: "Reducción de costos",
    costText: "Reducción de los costos mano de obra y aumento de eficiencia de principio a fin.",
    breakHome: "Automatiza tu proceso de compras",
    platformMod: "Módulos de la Plataforma",
    dashRep: "Dashboards y reportes",
    poCreation: "Elaboración y publicación de órdenes de compra",
    poConf: "Confirmación de orden de compra por parte del proveedor en el portal y surtimiento",
    goodRec: "Registro y publicación de entrada de mercancía",
    invoiceUp: "Carga de Facturas y validación fiscal",
    commVal: "Validación comercial (OC, entrada y factura)",
    invoiceAcc: "Contabilización de facturas y programación de pagos",
    payRun: "Ejecución y publicación de pago",
    suppPay: "Complemento de pago",
    diffButt: "Diferenciadores"
  },
  purchase: {
    bannerPM: "Gestión de<br>Compras",
    pmText: "Netcurio permite <b>crear y gestionar fácilmente órdenes de compra en tiempo-real</b>, reduciendo tiempo y costo en el proceso",
    cardTitle1: "Portal Netcurio",
    cardSub1: "Registro",
    cardText1: "Invitación al proveedor",
    cardSubText1: "Datos del proveedor",
    cardTitle2: "Creación",
    cardSub2: "Orden de compra",
    cardText2: "Creación de orden de compra por parte del cliente",
    cardSubText2: "Notificación al proveedor",
    cardTitle3: "Confirmación",
    cardSub3: "Confirmación de órdenes de compra",
    cardText3: "Confirmación o rechazo de orden de compra",
    cardTitle4: "Recepción",
    cardSub4: "Recibo de mercancía",
    cardText4: "El proveedor recibe la confirmación del recibo de la mercancía o servicio del cliente",
    cardTitle5: "Facturación",
    cardSub5: "Carga de facturas",
    cardText5: "Una vez que la mercancía es recibida, el proveedor podrá cargar la factura",
    pmSectionTitle: "Detalle de la Gestión de compras",
    pmRegTitle: "Registro",
    pmRegText: "Puede enviar una invitación al proveedor para colaborar en Netcurio con solo información básica del proveedor.",
    pmCreTitle: "Creación",
    pmCreText: "Puede crear una orden de compra, materiales o servicios, especificando tipo de compra. Se envía una notificación por correo electrónico al proveedor en tiempo real y él puede acceder a los datos del Orden de Compra desde la plataforma.",
    pmConfTitle: "Confirmación",
    pmConfText: "Todos los miembros de la cadena de aprobación pueden verificar el estado de las solicitudes de compra y aprobar o rechazar los pedidos de compra en cualquier parte del mundo desde cualquier dispositivo.",
    pmRecTitle: "Recepción",
    pmRecText: "A través de la plataforma de Netcurio, el proveedor puede rastrear y ser notificado de la entrega del producto o servicio."
  },
  invoice: {
    bannerIM: "Gestión de<br>Facturas",
    imText: "Netcurio permite un <b>proceso automático de revisión y verificaciones de facturas</b>, elimina el trabajo manual y el papel. El equipo de cuentas por pagar solo manejara las excepciones, enfocándose en agregar valor estratégico a su compañía",
    cardTitle1: "Portal Netcurio",
    cardSub1: "Preparación",
    cardText1: "Carga de Factura",
    cardSubText1: "Archivado",
    cardTitle2: "Reconocimiento de Factura",
    cardSub2: "Transformación",
    cardText2: "Validación fiscal",
    cardTitle3: "Verificación de factura",
    cardSub3: "Verificación",
    cardText3: "Reglas de validación Comercial",
    cardTitle4: "Control",
    cardSub4: "Manejo de excepciones",
    cardText4: "Revisión y comentarios",
    cardTitle5: "Fin del proceso de facturación",
    cardSub5: "Aprobado",
    cardText5: "Estatus de factura",
    cardTitle6: "Pago",
    cardSub6: "Estatus de pago",
    autoRecon: "Conciliaciones &nbsp; automatizadas",
    imSectionTitle: "Detalles de la Gestión de Facturas",
    imInvTitle: "Carga de facturas",
    imInvText: "La gestión de facturas en Netcurio te permite gestionar la facturación electrónica, cumpliendo con todas las normas gubernamentales en su país. Además, sus proveedores pueden optar fácilmente por enviar las facturas por correo electrónico como una opción y se procesan automáticamente en la plataforma Netcurio.",
    imVerTitle: "Verificación",
    imVerText: "Puede administrar todas las validaciones comerciales y reglas de negocio requeridas para pago de facturas. Netcurio procesará automáticamente las conciliaciones, utilizando la información de la Orden de Compra, Recibo de Servicio o Producto y la Factura.",
    imValTitle: "Validación",
    imValText: "Netcurio permite configurar facturas que coinciden con los niveles de tolerancia y las reglas, de modo que los revisores solo deben participar para manejar las excepciones a estas reglas."
  },
  payment: {
    bannerPC: "Control<br>de Pago",
    cardTitle1: "Facturación contable y programación de pagos",
    cardText1: "Ser capaz de seguir las condiciones de pago y los acuerdos comerciales con su proveedor. Netcurio muestra automáticamente la fecha de publicación de la ejercución de pago.",
    cardTitle2: "Publicación de la ejecución de pago",
    cardText2: "Puede mostrar el estatus del proceso de pago a sus proveedores.",
    cardTitle3: "Carga de complemento de pago",
    cardText3: "El complemento de pago se puede cargar y conciliar con las facturas correspondientes."
  },
  dashboard: {
    bannerDBR: "Control: Indicadores y Reportes",
    cardTitle1: "Cuadros de mando con indicadores clave de rendimiento",
    cardTitle2: "Visibilidad y trazabilidad desde la orden de compra hasta la factura",
    cardTitle3: "Descarga de reportes de cada módulo"
  },
  about: {
    bannerAU: "Sobre Nosotros",
    auTitle: "Quienes Somos",
    auText1: "Netcurio es una empresa de tecnología con un fuerte conocimiento y experiencia en aportar eficiencia a los procesos administrativos.",
    auText2: "Nuestras soluciones proporcionan eficiencias operativas en el <b>proceso de compra a pago</b>, aprovechando las mejores prácticas y tecnología. ",
    auText3: "Somos un equipo centrado en el cliente, escuchando y entendiendo las necesidades, proporcionando soporte y soluciones de clase mundial 7x24 para cualquier problema.",
    auHere: "Aquí estamos para ayudarte"
  },
  diff: {
    bannerDiff: "Diferenciadores",
    diffText1: "Nuestra plataforma está específicamente adaptada a las necesidades de cada industria, no sólo proporcionando eficiencia en el proceso de compras, sino también en el cumplimiento de las normativas fiscales locales de cada país en el que operamos.",
    diffText2: "La adopción de nuestra plataforma permite a pequeñas, medianas y grandes organizaciones a optimizar sus compras. También pueden permitir nuevos procesos como la compra de consignación y gestión de compras.",
    boxTitle: "Algunas de las características clave de nuestra plataforma son:",
    box1: "Plataforma Cloud Software As A Service (SaaS)",
    box2: "Reportes y análisis avanzados",
    box3: "Soporte por teléfono, correo electrónico y chat",
    box4: "Adopción a las normas fiscales y del gobierno local",
    box5: "Planes de suscripción adaptables a diferentes tamaños de empresas",
    box6: "Sin pagos por adicionales",
    box7: "Rápido retorno de inversión",
    box8: "Sin costo para proveedores",
    diffMain: "Beneficios Principales",
    diffRed: "Reducción de facturas sin Orden de Compra",
    diffComp: "<span>100%</span> Cumplimiento en el proceso de adquisición",
    diffImprov: "Mejora",
    diffLessSat: "<span>50-75%</span> Menos saturación de líneas de comunicación con proveedores",
    diffLessTime: "<span>85%</span> Menos tiempo dedicado a solicitudes y reclamos de proveedores",
    diffInc: "Mayores descuentos en negociaciones con proveedores",
    diffBett: "Mejores condiciones de pago en negociaciones con proveedores",
    diffReduce: "Reducir",
    diffReduction: "<span>25-50%</span> Reducción de costos de adquisición y cuentas por pagar",
    diffLowerCost: "<span>20-50%</span> Menor costo por factura contabilizada",
    diffLowerErr: "<span>75%</span> Menor costo por errores y procesamiento de facturas duplicadas",
    diffInves: "<span>3 Meses</span> Tiempo de retorno de la inversión"
  },
  contact: {
    bannerContact: "Contáctanos ",
    subTitleContact: "Nuestro equipo está aquí para ayudarte",
    contactTitle: "¿Necesitas ayuda?",
    contactText: "Netcurio elimina el trabajo manual y el papeleo con un proceso de facturación automático utilizando el equipo de cuentas por pagar solo por excepción y agregando valor estratégico a su empresa.",
    inputName: "Nombre*",
    inputLast: "Apellido*",
    inputCompany: "Compañía*",
    inputCorporateEMail: "Correo de compañía*",
    inputCountry: "País",
    inputPhone: "Número Telefónico",
    inputErp: "Escribe tu ERP",
    inputComment: "Comentarios",
    anchorLegal: 'He leído el <a href="legal.html" class="field-style linkColor" target="_blank">Aviso de Privacidad</a>',
    validMessage: "Su mensaje fue enviado exitosamente",
    submitContact: "Enviar"
  }
};