import {esp} from './spanish.js';

(function () {
  "use strict";
  document.addEventListener('DOMContentLoaded', function () {
    //Define language reload anchors
    const espLang = document.querySelector('.spanish');
    const engLang = document.querySelector('.english');
    const espMobile = document.querySelector('.spa-mobile');
    const engMobile = document.querySelector('.eng-mobile');

    //Define language via window hash
    function verifyHash() {
      if(window.location.hash){
        let currentHash = location.hash;
        let currentPath = location.pathname;
        if(currentHash === "#esp"){

          espLang.classList.add('active-language');
          engLang.classList.remove('active-language');

          //Desktop Menu Translations
          solutions.innerHTML = esp.menu.solutions;
          pmSection.innerHTML = esp.menu.pmSection;
          pmBanner.innerHTML = esp.menu.pmSection;
          pmSummary.innerHTML = esp.menu.pmSummary;
          imSection.innerHTML = esp.menu.imSection;
          imBanner.innerHTML = esp.menu.imSection;
          imSummary.innerHTML = esp.menu.imSummary;
          pcSection.innerHTML = esp.menu.pcSection;
          pcBanner.innerHTML = esp.menu.pcSection;
          pcSummary.innerHTML = esp.menu.pcSummary;
          dbrSection.innerHTML = esp.menu.dbrSection;
          dbrBanner.innerHTML = esp.menu.dbrSection;
          dbrSummary.innerHTML = esp.menu.dbrSummary;
          company.innerHTML = esp.menu.company;
          auSection.innerHTML = esp.menu.auSection;
          auBanner.innerHTML = esp.menu.auSection;
          auSummary.innerHTML = esp.menu.auSummary;
          diffSection.innerHTML = esp.menu.diffSection;
          diffBanner.innerHTML = esp.menu.diffSection;
          diffSummary.innerHTML = esp.menu.diffSummary;
          contact.innerHTML = esp.menu.contact;
          login.innerHTML = esp.menu.login;

          //Mobile menu translation
          homeMobile.innerHTML = esp.menuMobile.homeMobile;
          solutionsMobile.innerHTML = esp.menu.solutions;
          pmMobile.innerHTML = esp.menu.pmSection;
          imMobile.innerHTML = esp.menu.imSection;
          pcMobile.innerHTML = esp.menu.pcSection;
          dbrMobile.innerHTML = esp.menu.dbrSection;
          companyMobile.innerHTML = esp.menu.company;
          auMobile.innerHTML = esp.menu.auSection;
          diffMobile.innerHTML = esp.menu.diffSection;
          langMobile.innerHTML = esp.menuMobile.langMobile;
          engMobile.innerHTML = esp.menuMobile.engMobile;
          espMobile.innerHTML = esp.menuMobile.espMobile;
          contactMobile.innerHTML = esp.menu.contact;
          loginMobile.innerHTML = esp.menu.login;
          legalMobile.innerHTML = esp.menuMobile.legal;

          //Footer translations
          solutionsFoot.innerHTML = esp.menu.solutions;
          pmFoot.innerHTML = esp.menu.pmSection;
          imFoot.innerHTML = esp.menu.imSection;
          pcFoot.innerHTML = esp.menu.pcSection;
          dbrFoot.innerHTML = esp.menu.dbrSection;
          companyFoot.innerHTML = esp.menu.company;
          auFoot.innerHTML = esp.menu.auSection;
          diffFoot.innerHTML = esp.menu.diffSection;
          legalFoot.innerHTML = esp.menuMobile.legal;
          contactFoot.innerHTML = esp.menu.contact;
          loginFoot.innerHTML = esp.menu.login;

          if(currentPath === "/index.html" || currentPath === "/"){
            /* indexTitle.innerHTML = esp.index.indexTitle;
            bannerHome.innerHTML = esp.index.bannerHome; */
            demoHome.innerHTML = esp.index.demoHome;
            contactHome.innerHTML = esp.index.contactHome;
            benefitsHome.innerHTML = esp.index.benefitsHome;
            commTitle.innerHTML = esp.index.commTitle;
            commText.innerHTML = esp.index.commText;
            effTitle.innerHTML = esp.index.effTitle;
            effText.innerHTML = esp.index.effText;
            compTitle.innerHTML = esp.index.compTitle;
            compText.innerHTML = esp.index.compText;
            costTitle.innerHTML = esp.index.costTitle;
            costText.innerHTML = esp.index.costText;
            breakHome.innerHTML = esp.index.breakHome;
            platformMod.innerHTML = esp.index.platformMod;
            dashRep.innerHTML = esp.index.dashRep;
            poCreation.innerHTML = esp.index.poCreation;
            poConf.innerHTML = esp.index.poConf;
            goodRec.innerHTML = esp.index.goodRec;
            invoiceUp.innerHTML = esp.index.invoiceUp;
            commVal.innerHTML = esp.index.commVal;
            invoiceAcc.innerHTML = esp.index.invoiceAcc;
            payRun.innerHTML = esp.index.payRun;
            suppPay.innerHTML = esp.index.suppPay;
            diffButt.innerHTML = esp.index.diffButt;
          }

          if(currentPath === "/purchase_management.html"){
            bannerPM.innerHTML = esp.purchase.bannerPM;
            pmText.innerHTML = esp.purchase.pmText;
            cardTitle1.innerHTML = esp.purchase.cardTitle1;
            cardSub1.innerHTML = esp.purchase.cardSub1;
            cardText1.innerHTML = esp.purchase.cardText1;
            cardSubText1.innerHTML = esp.purchase.cardSubText1;
            cardTitle2.innerHTML = esp.purchase.cardTitle2;
            cardSub2.innerHTML = esp.purchase.cardSub2;
            cardText2.innerHTML = esp.purchase.cardText2;
            cardSubText2.innerHTML = esp.purchase.cardSubText2;
            cardTitle3.innerHTML = esp.purchase.cardTitle3;
            cardSub3.innerHTML = esp.purchase.cardSub3;
            cardText3.innerHTML = esp.purchase.cardText3;
            cardTitle4.innerHTML = esp.purchase.cardTitle4;
            cardSub4.innerHTML = esp.purchase.cardSub4;
            cardText4.innerHTML = esp.purchase.cardText4;
            cardTitle5.innerHTML = esp.purchase.cardTitle5;
            cardSub5.innerHTML = esp.purchase.cardSub5;
            cardText5.innerHTML = esp.purchase.cardText5;
            pmSectionTitle.innerHTML = esp.purchase.pmSectionTitle;
            pmSectionTitleMobile.innerHTML = esp.purchase.pmSectionTitle;
            pmRegTitle.innerHTML = esp.purchase.pmRegTitle;
            pmRegText.innerHTML = esp.purchase.pmRegText;
            pmCreTitle.innerHTML = esp.purchase.pmCreTitle;
            pmCreText.innerHTML = esp.purchase.pmCreText;
            pmConfTitle.innerHTML = esp.purchase.pmConfTitle;
            pmConfText.innerHTML = esp.purchase.pmConfText;
            pmRecTitle.innerHTML = esp.purchase.pmRecTitle;
            pmRecText.innerHTML = esp.purchase.pmRecText;
            pmDemo.innerHTML = esp.general.requestDemo;
          }

          if(currentPath === "/invoice_management.html"){
            bannerIM.innerHTML = esp.invoice.bannerIM;
            imText.innerHTML = esp.invoice.imText;
            cardTitle1.innerHTML = esp.invoice.cardTitle1;
            cardSub1.innerHTML = esp.invoice.cardSub1;
            cardText1.innerHTML = esp.invoice.cardText1;
            cardSubText1.innerHTML = esp.invoice.cardSubText1;
            cardTitle2.innerHTML = esp.invoice.cardTitle2;
            cardSub2.innerHTML = esp.invoice.cardSub2;
            cardText2.innerHTML = esp.invoice.cardText2;
            cardTitle3.innerHTML = esp.invoice.cardTitle3;
            cardSub3.innerHTML = esp.invoice.cardSub3;
            cardText3.innerHTML = esp.invoice.cardText3;
            cardTitle4.innerHTML = esp.invoice.cardTitle4;
            cardSub4.innerHTML = esp.invoice.cardSub4;
            cardText4.innerHTML = esp.invoice.cardText4;
            cardTitle5.innerHTML = esp.invoice.cardTitle5;
            cardSub5.innerHTML = esp.invoice.cardSub5;
            cardText5.innerHTML = esp.invoice.cardText5;
            cardTitle6.innerHTML = esp.invoice.cardTitle6;
            cardSub6.innerHTML = esp.invoice.cardSub6;
            autoReconMobile.innerHTML = esp.invoice.autoRecon;
            autoRecon.innerHTML = esp.invoice.autoRecon;
            imSectionTitle.innerHTML = esp.invoice.imSectionTitle;
            imSectionTitleMobile.innerHTML = esp.invoice.imSectionTitle;
            imInvTitle.innerHTML = esp.invoice.imInvTitle;
            imInvText.innerHTML = esp.invoice.imInvText;
            imVerTitle.innerHTML = esp.invoice.imVerTitle;
            imVerText.innerHTML = esp.invoice.imVerText;
            imValTitle.innerHTML = esp.invoice.imValTitle;
            imValText.innerHTML = esp.invoice.imValText;
            imDemo.innerHTML = esp.general.requestDemo;
          }

          if(currentPath === "/payment_control.html"){
            bannerPC.innerHTML = esp.payment.bannerPC;
            cardTitle1.innerHTML = esp.payment.cardTitle1;
            cardText1.innerHTML = esp.payment.cardText1;
            cardTitle2.innerHTML = esp.payment.cardTitle2;
            cardText2.innerHTML = esp.payment.cardText2;
            cardTitle3.innerHTML = esp.payment.cardTitle3;
            cardText3.innerHTML = esp.payment.cardText3;
            pcDemo.innerHTML = esp.general.requestDemo;
          }

          if(currentPath === "/dashboards_n_reports.html"){
            bannerDBR.innerHTML = esp.dashboard.bannerDBR;
            cardTitle1.innerHTML = esp.dashboard.cardTitle1;
            cardTitle2.innerHTML = esp.dashboard.cardTitle2;
            cardTitle3.innerHTML = esp.dashboard.cardTitle3;
            dbrDemo.innerHTML = esp.general.requestDemo;
          }

          if(currentPath === "/about_us.html"){
            bannerAU.innerHTML = esp.about.bannerAU;
            auTitle.innerHTML = esp.about.auTitle;
            auText1.innerHTML = esp.about.auText1;
            auText2.innerHTML = esp.about.auText2;
            auText3.innerHTML = esp.about.auText3;
            auHere.innerHTML = esp.about.auHere;
            auDemo.innerHTML = esp.general.requestDemo;
          }

          if(currentPath === "/contact_us.html"){
            bannerContact.innerHTML = esp.contact.bannerContact;
            subTitleContact.innerHTML = esp.contact.subTitleContact;
            contactTitle.innerHTML = esp.contact.contactTitle;
            contactText.innerHTML = esp.contact.contactText;
            inputName.placeholder = esp.contact.inputName;
            inputLast.placeholder = esp.contact.inputLast;
            inputCompany.placeholder = esp.contact.inputCompany;
            inputCorporateEMail.placeholder = esp.contact.inputCorporateEMail;
            inputCountry.placeholder = esp.contact.inputCountry;
            inputPhone.placeholder = esp.contact.inputPhone;
            inputErp.placeholder = esp.contact.inputErp;
            inputComment.placeholder = esp.contact.inputComment;
            anchorLegal.innerHTML = esp.contact.anchorLegal;
            validMessage.innerHTML = esp.contact.validMessage;
            submitContact.innerHTML = esp.contact.submitContact;
          }

          if(currentPath === "/legal.html"){
            legalTitle.innerHTML = esp.general.legal;
            legalDemo.innerHTML = esp.general.requestDemo;
          }

          if(currentPath === "/differentiating_features.html"){
            bannerDiff.innerHTML = esp.diff.bannerDiff;
            diffText1.innerHTML = esp.diff.diffText1;
            diffText2.innerHTML = esp.diff.diffText2;
            boxTitle.innerHTML = esp.diff.boxTitle;
            box1.innerHTML = esp.diff.box1;
            box2.innerHTML = esp.diff.box2;
            box3.innerHTML = esp.diff.box3;
            box4.innerHTML = esp.diff.box4;
            box5.innerHTML = esp.diff.box5;
            box6.innerHTML = esp.diff.box6;
            box7.innerHTML = esp.diff.box7;
            box8.innerHTML = esp.diff.box8;
            diffMain.innerHTML = esp.diff.diffMain;
            diffMainMobile.innerHTML = esp.diff.diffMain;
            diffRed.innerHTML = esp.diff.diffRed;
            diffComp.innerHTML = esp.diff.diffComp;
            diffImprov.innerHTML = esp.diff.diffImprov;
            diffLessSat.innerHTML = esp.diff.diffLessSat;
            diffLessTime.innerHTML = esp.diff.diffLessTime;
            diffInc.innerHTML = esp.diff.diffInc;
            diffBett.innerHTML = esp.diff.diffBett;
            diffReduce.innerHTML = esp.diff.diffReduce;
            diffReduction.innerHTML = esp.diff.diffReduction;
            diffLowerCost.innerHTML = esp.diff.diffLowerCost;
            diffLowerErr.innerHTML = esp.diff.diffLowerErr;
            diffInves.innerHTML = esp.diff.diffInves;
            diffDemo.innerHTML = esp.general.requestDemo;
          }
        }
      }
    }

    //Define language reload onclick according language
    engLang.addEventListener('click', function () {
      sessionStorage.removeItem('spanish');
      this.classList.add('active-language');
      window.location.href = window.location.toString().split(/[?#]/)[0];
    });

    espLang.addEventListener('click', function () {
      window.sessionStorage.setItem('spanish', 'esp');
      location.reload();
    });

    //For responsive menu functionality the translate is independent
    engMobile.addEventListener('click', function () {
      sessionStorage.removeItem('spanish');
      window.location.href = window.location.toString().split(/[?#]/)[0];
      verifyHash();
    });

    espMobile.addEventListener('click', function () {
      window.sessionStorage.setItem('spanish', 'esp');
      location.reload();
    });

    //When reload or change of page we need to verify if exist the session item "spanish"
    window.addEventListener('load', function () {
      if(window.sessionStorage.getItem('spanish')){
        window.location.hash = window.sessionStorage.getItem('spanish');
        verifyHash();
      }
    });
  });
})();




